import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SuccessResultIcon } from '../../components/fragments/result_icon/SuccessResultIcon';
import { AudioProvider } from '../../context/AudioContext';
import { LESSON } from '../../shared/constants';
import { PRONONCIATION_ERRORS_STORAGE_KEY } from '../../shared/constants/storage';
import translate, { getSupportedLanguage } from '../../utils/translate';
import { AudioRecorder } from '../audio_recorder/AudioRecorder';
import { TaskCaption } from '../fragments/task_caption/TaskCaption';
import LessonExerciseTaskContent from './LessonExerciseTaskContent';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { errorAlert } from '../../redux/actions/alert';
import './LessonExerciseTaskAudio.scss';

const MATCH_METHOD_STATUS_ERROR = 'error';

class LessonExerciseTaskAudio extends React.Component {
  microphone = null;
  static propTypes = {
    attempts: PropTypes.number,
    round: PropTypes.number,
    success: PropTypes.bool,
    successLevel: PropTypes.number,
    task: PropTypes.object,
    next: PropTypes.func,
    onAudioResult: PropTypes.func,
    onStartRecord: PropTypes.func,
  };
  constructor(props) {
    super(props);

    this.state = {
      audioProcessing: false,
      errorMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.task !== this.props.task) {
      this.setState({ errorMessage: '' });
    }
  }

  renderButtons = (task) => {
    const { success } = this.props;

    if (success) {
      return (
        <SuccessResultIcon containerClassName="audio-result-success-icon-container" />
      );
    } else {
      return (
        <div style={{ height: 20 }}>
          <AudioRecorder />
        </div>
      );
    }
  };

  onStartRecord = () => {
    this.setState({ audioProcessing: true, errorMessage: '' });
    this.props.onStartRecord();
  };

  parsePrononciationErrors = (matchResponse) => {
    const errors = {};
    const pronunciationErrors =
      matchResponse.methods.pronunciation_assessment?.pronunciation_errors;
    if (pronunciationErrors) {
      for (const error of pronunciationErrors) {
        if (!errors[error.error_id]) {
          errors[error.error_id] = 1;
        } else {
          errors[error.error_id]++;
        }
      }
    }
    return errors;
  };

  handlePrononciationErrors = (proponciationErrors) => {
    const currentErrors =
      JSON.parse(localStorage.getItem(PRONONCIATION_ERRORS_STORAGE_KEY)) || {};
    for (const errorId of Object.keys(proponciationErrors)) {
      if (!currentErrors[errorId]) {
        currentErrors[errorId] = proponciationErrors[errorId];
      } else {
        currentErrors[errorId] += proponciationErrors[errorId];
      }
    }
    localStorage.setItem(
      PRONONCIATION_ERRORS_STORAGE_KEY,
      JSON.stringify(currentErrors)
    );
  };

  parseMatchError = (matchMethod) => {
    return this.props.t(
      `matchError${matchMethod.error?.code}`,
      this.props.t('lessonExerciseTaskAudioMessageWrong')
    );
  };

  onResult = (error, result) => {
    this.setState({ audioProcessing: false });

    if (error) {
      console.error(error);
      /*
       * {
       *   "status": "error",
       *   "error": {
       *       "code": "504",
       *       "message": "Unable to connect to server"
       *   }
       * }
       */
      let message;
      switch (error.status) {
        case '403':
          message = this.props.t('microphonePermissionsError');
          break;
        case '422':
          message = this.props.t('lessonExerciseTaskAudioMessageRelease');
        case '500':
        case '504':
        default:
          message = this.props.t('lessonExerciseTaskAudioMessageWent');
      }
      this.props.errorAlert({ message });
    } else {
      let message;

      if (!result) {
        this.props.errorAlert({
          message: this.props.t('lessonExerciseTaskAudioMessageWrong'),
          dismissable: true,
        });
      } else if (
        result?.methods?.pronunciation_assessment &&
        result.methods.pronunciation_assessment.phonetic_matching_score >
          LESSON.AUDIO.RIGHT_THRESHOLD
      ) {
        // Show green indicator and move on with next phrase
        message = this.props.t('lessonExerciseTaskAudioMessageRight');
        this.props.onAudioResult(LESSON.AUDIO.RIGHT);
      // } else if (
      //   result?.methods?.pronunciation_assessment &&
      //   result.methods.pronunciation_assessment.phonetic_matching_score >
      //     LESSON.AUDIO.ALMOST_THRESHOLD
      // ) {
      //   // Show green indicator and move on with next phrase
      //   message = this.props.t('lessonExerciseTaskAudioMessageAlmost');
      //   this.props.onAudioResult(LESSON.AUDIO.ALMOST);
      } else if (result?.methods.match.status === MATCH_METHOD_STATUS_ERROR) {
        const errorMessage = this.parseMatchError(result.methods.match);
        this.setState({ errorMessage });
        this.props.onAudioResult(LESSON.AUDIO.WRONG);
      } else {
        // Show content to improve pronunciation error
        message = this.props.t('lessonExerciseTaskAudioMessageAgain');
        this.props.onAudioResult(LESSON.AUDIO.WRONG);
      }

      if (result) {
        const proponciationErrors = this.parsePrononciationErrors(result);
        this.handlePrononciationErrors(proponciationErrors);
      }
    }
  };
  render() {
    const { attempts, success, successLevel, task, correctOption } = this.props;
    const { audioProcessing, errorMessage } = this.state;
    const lng = getSupportedLanguage(this.props.lng);

    if (!task) return <React.Fragment />;

    return (
      <AudioProvider
        task={task}
        onStartRecord={this.onStartRecord}
        onResult={this.onResult}
        attempts={attempts}
      >
        <div className="task-container">
          <Row className="round-container">
            <Col>
              <LessonExerciseTaskContent
                task={task}
              ></LessonExerciseTaskContent>
              <TaskCaption
                taskArticle={task.article}
                taskWord={task.word}
                taskAnnotatedWord={task.annotatedWord}
                success={success}
                attempts={attempts}
                processing={audioProcessing}
                feedbackMessage={translate(task, 'feedback', lng)}
                errorMessage={errorMessage}
                correctOption={correctOption}
                successLevel={successLevel}
              />
              {this.renderButtons(task)}
            </Col>
          </Row>
        </div>
      </AudioProvider>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ errorAlert }, dispatch);
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withNamespaces()(LessonExerciseTaskAudio)
);
