import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useUnitsList } from '../hooks/unit/useUnitsList';
import { useAppConfig } from '../hooks/config/useAppConfig';

export const AudioContext = createContext({
  startRecord: () => {},
  playAudio: () => {},
  recording: false,
  analyzing: false,
  playing: false,
  audioUrl: null,
  voiceLoudLevel: 0,
  resultReceived: false,
  delayBeforeRecording: false,
  attempts: 0,
});

const MIN_VOICE_LOUD_LEVEL = 2.0;
export const DELAY_BEFORE_RECORDING = 3000;

export const AudioProvider = (props) => {
  const [recording, setRecording] = useState(false);
  const [analyzing, setAnalyzing] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [voiceLoudLevel, setVoiceLoudLevel] = useState(0);
  const [resultReceived, setResultReceived] = useState(false);
  const [delayBeforeRecording, setDelayBeforeRecording] = useState(false);
  const input = useRef(null);
  const connection = useRef(null);
  const audioContext = useRef(null);
  const processor = useRef(null);
  const globalStream = useRef(null);
  const analayzer = useRef(null);
  const waitForResultTimeout = useRef(null);
  const forceStopRecordTimeout = useRef(null);
  const chunks = useRef([]);
  const samples = useRef([]);
  const { data: appConfig } = useAppConfig();

  const anonymousUser = useSelector((state) => state.anonymousUser);
  const authenticatedUser = useSelector((state) => state.authenticatedUser);
  const userProfile = useSelector((state) => state.userProfile);

  const phrase = useMemo(() => {
    const { task } = props;
    let result = '';
    if (task) {
      result = task.word;
      if (task.options?.[0] && task.options?.[0].length) {
        result = task.options[0];
      }
    }
    return result;
  }, [props.task]);

  const user = useMemo(() => {
    const userId = anonymousUser ? anonymousUser : authenticatedUser?._id;
    const now = new Date();

    return {
      user_id: userId,
      gender: userProfile?.gender,
      // proficiency_level: userProfile?.level,
      age: userProfile?.birthyear
        ? now.getFullYear() - userProfile?.birthyear
        : null,
      accent: userProfile?.language,
    };
  }, [userProfile, anonymousUser, authenticatedUser]);

  useEffect(() => {
    resetComponent();
  }, [phrase]);

  const resetComponent = () => {
    setRecording(false);
    setAnalyzing(false);
    setAudioUrl(null);
    setVoiceLoudLevel(0);
    setResultReceived(false);

    clearTimeout(waitForResultTimeout.current);
    waitForResultTimeout.current = null;

    closeAaronConnection();

    stopAudioContext();

    chunks.current = [];
    samples.current = [];
  };

  const resultHandler = (matchResponse) => {
    clearTimeout(waitForResultTimeout.current);

    setAnalyzing(false);
    setResultReceived(true);

    if (matchResponse == null) {
      console.error('resultHandler: Other error');
    }

    stopAudioContext();

    closeAaronConnection();

    props.onResult(null, matchResponse);
  };

  const setAudioContext = () => {
    try {
      const AudioContext =
        window.AudioContext ||
        window.webkitAudioContext ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        null;

      audioContext.current = new AudioContext({ latencyHint: 'interactive' });

      processor.current = audioContext.current.createScriptProcessor(
        2048,
        1,
        1
      );
      processor.current.connect(audioContext.current.destination);

      audioContext.current.resume();
    } catch (error) {
      console.error('Unable to set audio context');
    }
  };

  const stopAudioContext = () => {
    clearTimeout(forceStopRecordTimeout.current);
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaStream/getTracks
    try {
      const track = globalStream.current.getTracks();
      if (Array.isArray(track) && track.length > 0) {
        track[0].stop();
      }
      input.current.disconnect(processor.current);
      processor.current.disconnect(audioContext.current.destination);
    } catch (error) {
      // Do nothing because audio context was already stopped
    }

    if (audioContext.current) {
      // Close audio context and reset parameters
      audioContext.current
        .close()
        .then(() => {
          input.current = null;
          processor.current = null;
          audioContext.current = null;
          globalStream.current = null;
          analayzer.current = null;
        })
        .catch(() => {
          // Do nothing because audio context was already stopped
        });
    }
  };

  const downsampleBuffer = (buffer, sampleRate, outSampleRate) => {
    if (outSampleRate == sampleRate) {
      return buffer;
    }

    if (outSampleRate > sampleRate) {
      throw 'downsampling rate should be smaller than input sample rate';
    }

    const sampleRateRatio = sampleRate / outSampleRate;
    const newLength = Math.round(buffer.length / sampleRateRatio);
    const result = new Int16Array(newLength);
    let offsetResult = 0;
    let offsetBuffer = 0;

    while (offsetResult < result.length) {
      const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
      let accum = 0;
      let count = 0;
      for (
        let i = offsetBuffer;
        i < nextOffsetBuffer && i < buffer.length;
        i++
      ) {
        accum += buffer[i];
        count++;
      }

      result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
    }

    return result.buffer;
  };

  const initAaronConnection = () => {
    closeAaronConnection();

    // Initiate websocket connection and start sending data
    connection.current = io(appConfig.analyseApiUrl, {
      transportOptions: {
        polling: {
          extraHeaders: {
            'goethe-client-auth-token': appConfig.analyseApiKey,
          },
        },
      },
    });

    connection.current.connect();

    // Create object for data initialization
    const initData = {
      audio_metadata: {
        encoding: 'LINEAR16',
        sample_rate: 16000,
      },
      user,
      label: { match_choice: phrase.toLowerCase() },
      methods: [{ method: 'match' }, { method: 'pronunciation_assessment' }],
    };
    // Send data for initialization
    connection.current.emit('init_with_metadata', initData);

    // Get the match result from the server
    connection.current.on('result', resultHandler);
  };

  const closeAaronConnection = () => {
    if (connection.current) {
      connection.current.off('result', resultHandler);
      connection.current.disconnect();
    }
    connection.current = null;
  };

  const onStartRecord = () => {
    props.onStartRecord();
    setDelayBeforeRecording(true);

    setTimeout(() => {
      setDelayBeforeRecording(false);
      startRecord();
    }, DELAY_BEFORE_RECORDING + 500);
  };

  const startRecord = async () => {
    try {
      // console.log('Start recording');
      setRecording(true);
      setResultReceived(false);
      setAnalyzing(false);
      initAaronConnection();

      // Get audio context from browser
      setAudioContext();

      try {
        // Get user media access
        globalStream.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const mediaRecorder = new MediaRecorder(globalStream.current);

        chunks.current = [];
        samples.current = [];

        mediaRecorder.ondataavailable = (e) => {
          chunks.current.push(e.data);
        };

        mediaRecorder.onstop = async (e) => {
          const blob = new Blob(chunks.current, {
            type: 'audio/ogg; codecs=opus',
          });

          setAudioUrl(window.URL.createObjectURL(blob));
          setRecording(false);
        };

        analayzer.current = audioContext.current.createAnalyser();

        input.current = audioContext.current.createMediaStreamSource(
          globalStream.current
        );
        input.current.connect(analayzer.current);
        input.current.connect(processor.current);

        const frequencyData = new Uint8Array(
          analayzer.current.frequencyBinCount
        );
        let stopRecordTimeout = null;

        processor.current.onaudioprocess = (e) => {
          // AudioBuffer Interface returns a Float32Array
          const inputBuffer = e.inputBuffer.getChannelData(0);

          // Downsampled at before sending for analyzing
          const binaryData = downsampleBuffer(inputBuffer, 44100, 16000);

          samples.current.push(binaryData);

          connection.current.emit('analyze', binaryData);

          if (analayzer.current) {
            analayzer.current.getByteFrequencyData(frequencyData);

            const sum = frequencyData.reduce((a, b) => a + b, 0);
            const avg = sum / frequencyData.length || 0;
            if (avg !== voiceLoudLevel) {
              setVoiceLoudLevel(avg);
            }

            if (avg > MIN_VOICE_LOUD_LEVEL) {
              clearTimeout(stopRecordTimeout);
              stopRecordTimeout = setTimeout(() => {
                stopRecord();
              }, 1000);
            }
          }
        };

        mediaRecorder.start();

        // Force stop record user's voice in case the is always loud
        forceStopRecordTimeout.current = setTimeout(() => {
          stopRecord();
        }, 7000);
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          error.status = '403';
        }
        props.onResult(error);
        resetComponent();
      }
    } catch (error) {
      console.error(error);
      props.onResult(error);
      resetComponent();
    }
  };

  const stopRecord = () => {
    if (connection.current) {
      connection.current.emit('stop_analysis');
    }
    // console.log('Stop recording');
    setAnalyzing(true)
    stopAudioContext();
  };

  const playAudio = () => {
    if (!audioUrl) {
      return;
    }

    const audio = new Audio(audioUrl);

    const AudioContext =
      window.AudioContext ||
      window.webkitAudioContext ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      null;

    const audioContext = new AudioContext({ latencyHint: 'interactive' });

    const source = audioContext.createMediaElementSource(audio);
    const analyser = audioContext.createAnalyser();

    source.connect(analyser);
    analyser.connect(audioContext.destination);

    const interval = setInterval(() => {
      const freqData = new Uint8Array(analyser.frequencyBinCount);

      analyser.getByteFrequencyData(freqData);

      const barsCount = 6;
      const step = Math.floor(freqData.length / barsCount);
      for (let i = 0; i < barsCount; i++) {
        const bar = document.querySelector(`#bar${i}`);
        if (!bar) {
          continue;
        }

        const freq = freqData[i * step];
        const minBarHeight = 2;
        const maxBarHeight = 20;

        bar.style.height =
          Math.min(Math.max(minBarHeight, freq), maxBarHeight) + 'px';
      }
    }, 50);

    audio.addEventListener('ended', () => {
      clearInterval(interval);
      setPlaying(false);
      const icon = document.querySelector('.play-recorded-audio-icon');
      if (icon) {
        icon.style = {};
      }
    });

    setPlaying(true);

    audio.play();
  };

  return (
    <AudioContext.Provider
      value={{
        startRecord: onStartRecord,
        playAudio: playAudio,
        recording: recording,
        analyzing: analyzing,
        playing: playing,
        audioUrl: audioUrl,
        voiceLoudLevel: voiceLoudLevel,
        resultReceived: resultReceived,
        delayBeforeRecording: delayBeforeRecording,
        attempts: props.attempts,
      }}
    >
      {props.children}
    </AudioContext.Provider>
  );
};

AudioProvider.propTypes = {
  task: PropTypes.object,
  onStartRecord: PropTypes.func,
  onResult: PropTypes.func,
  attempts: PropTypes.number,
};

AudioProvider.defaultProps = {
  task: null,
  onStartRecord: () => {},
  onResult: () => {},
  attemps: 0,
};
